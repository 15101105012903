import * as React from "react"
import { HeadFC, Link } from "gatsby"

import Layout from "../components/layout"

const PartnerPage = () => {
  return (
    <Layout>
      
      <div className="relative overflow-hidden py-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
            <h1>
                <span className="block text-center text-lg font-semibold text-purple-600">Parnership Program</span>
                <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                Partner With ShieldStack
                </span>
            </h1>
            <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-15 lg:px-8 xl:pl-12">
                <p className="max-w-3xl text-lg text-gray-900">
                    Help your customers host and secure their websites while extending your earning potential. 
                    We offer a flat-fee structure that puts you in control of your earnings and makes it easy for your customers to understand.
                    We work with you on best practices to help you market the benefits of our solution to your clients.
                </p>
                <div className="text-center">
                    <Link to="/contact" className="mt-10 inline-block rounded-md border border-transparent bg-purple-600 px-5 py-4 text-base font-medium text-white shadow hover:bg-purple-700 sm:px-10">
                        Apply Now
                    </Link> 
                </div>
            </div>            
        </div>        
      </div>
    </div>

    </Layout>
  )
}

export default PartnerPage

export const Head: HeadFC = () => <title>About Shieldstack | Hosting and Web Security Platform</title>
